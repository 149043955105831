import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { MainContext } from "../../Contexts/MainContext";
import "./payment.css";

export const Payment = ({ config }) => {
  const { payment, setPayment, opt, slug } = useContext(MainContext);
  const [troco, setTroco] = useState(false);
  const [value, setValue] = useState();
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [tStyle, setTStyle] = useState({
    backgroundColor: "green",
    color: "white",
  });

  useEffect(() => {
    if (troco) {
      setTStyle({ backgroundColor: "green", color: "white" });
    } else {
      setTStyle({ backgroundColor: "red", color: "white" });
    }

    const _payments = [];

    _payments.push({ label: "Dinheiro", value: "Dinheiro" });

    if (opt.cartaoduplo) {
      _payments.push({ label: "Cartão Crédito", value: "Cartao Credito" });
      _payments.push({ label: "Cartão Débito", value: "Cartao Debito" });
    } else {
      _payments.push({ label: "Cartão", value: "Cartao" });
    }

    if (opt.pix) {
      _payments.push({ label: "PIX", value: "PIX" });
    }

    setPaymentTypes(_payments);

    // if (opt.pix) {
    //   setPaymentTypes([
    //     { label: "Dinheiro", value: "Dinheiro" },
    //     { label: "Cartão", value: "Cartao" },
    //     { label: "PIX", value: "PIX" },
    //   ]);
    // } else {
    //   setPaymentTypes([
    //     { label: "Dinheiro", value: "Dinheiro" },
    //     { label: "Cartão", value: "Cartao" },
    //   ]);
    // }
  }, [opt.pix, troco, opt.cartaoduplo]);

  const cartListStyle = {
    backgroundColor: "orange",
    color: config.theme.fontColor,
    padding: "10px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "0.5rem",
    marginBottom: "2rem",
    borderRadius: "10px",
    flex: 1,
  };

  const footer = (
    <div className="p-grid p-dir-col" style={{ padding: "5px" }}>
      <Button
        className="p-col"
        style={{ marginBottom: "5px", background: "green", color: "white" }}
        label="Confirmar"
        onClick={onConfirm}
      />
      <Button
        className="p-col"
        style={{ background: "red", color: "white" }}
        label="Não Preciso"
        onClick={onCancel}
      />
    </div>
  );

  function onHide() {
    setVisible(false);
  }

  function onConfirm() {
    if (!value || parseFloat(value) === 0) {
      setError(true);
    } else {
      setPayment({ ...payment, value: value });
      setTroco(true);
      setVisible(false);
    }
  }

  function onCancel() {
    setPayment({ ...payment, value: 0 });
    setVisible(false);
    setTroco(false);
  }

  function onToggle(e) {
    setVisible(true);
  }

  return (
    <div className="p-grid p-dir-col p-shadow-9" style={cartListStyle}>
      <Dialog
        header="Troco para:"
        footer={footer}
        visible={visible}
        style={{
          width: "60%",
          backgroundColor: "orange",
          borderRadius: "20px",
          filter: "drop-shadow(2px 4px 6px black)",
          border: "1px solid white",
        }}
        closable={false}
        modal
        onHide={onHide}
      >
        <InputNumber
          inputStyle={{ textAlign: "center", width: "100%" }}
          value={value}
          onValueChange={(e) => setValue(e.value)}
          mode="decimal"
          minFractionDigits={2}
          locale="pt-BR"
        />
        {error && <span style={{ color: "red" }}>(Valor é obrigatório)</span>}
      </Dialog>
      <Dropdown
        id="payment"
        value={payment?.type}
        options={paymentTypes}
        onChange={(e) => setPayment({ ...payment, type: e.value })}
        placeholder="Selecione a forma de pagamento"
        style={{
          borderRadius: "7px",
          border: "1px solid #656565",
          marginBottom: "10px",
          backgroundColor: "#323232",
          color: "white !important",
        }}
      />
      {payment?.type === "Dinheiro" && (
        <ToggleButton
          className="p-shadow-9"
          style={{ ...tStyle, borderRadius: "10px" }}
          checked={troco}
          onLabel={`Preciso de Troco para R$ ${value?.toFixed(2)}`}
          offLabel="Clique aqui se precisa de troco!"
          onChange={onToggle}
        />
      )}
      {payment?.type === "PIX" && opt.pixchave && (
        <div
          style={{
            borderRadius: "10px",
            fontWeight: "bold",
            color: "black",
            textAlign: "center",
          }}
        >
          <div
            style={{
              padding: "10px",
              margin: "8px",
              border: "1px solid white",
              borderRadius: "10px",
              backgroundColor: "black",
              color: "white",
              height: "60px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-11px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid white",
                borderRadius: "10px",
                padding: "2px 10px",
                fontSize: "12px",
              }}
            >
              Chave Pix
            </div>
            {opt.pixchave}
          </div>
          {opt.pixtexto}
        </div>
      )}
    </div>
  );
};
