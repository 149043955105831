import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { ToolBar } from "../Home/ToolBar";
import { MainContext } from "../../Contexts/MainContext";
import { InfoProduct } from "./InfoProduct";
import { ComposicaoCard } from "./ComposicaoCard";
import { Footer } from "../../Components/Footer";
import { ComplementoCard } from "./ComplementoCard";
import { ObservacaoCard } from "./ObservacaoCard";
import "./Custom.css";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

export const PizzaView = (props) => {
  const history = useHistory();
  const toast = useRef(null);
  const { addItemCart, editProduct, updateItemCart, cardapio } =
    useContext(MainContext);
  const { produto, type, index, quantidade: qtdItem, block } = editProduct;
  const { config, isOpen } = useContext(MainContext);
  const [totalPizza, setTotalPizza] = useState(0);
  const [totalOpcional, setTotalOpcional] = useState(0);
  const [quantidade, setQuantidade] = useState(type === "edit" ? qtdItem : 1);

  const [total, setTotal] = useState(0);
  const [complementos, setComplementos] = useState(produto?.complemento);
  const [composicoes, setComposicoes] = useState(produto?.composicao);
  const [observacao, setObservacao] = useState("");
  const [valid, setValid] = useState(false);
  const [validPizza, setValidPizza] = useState(false);
  const [validSup, setValidSup] = useState(false);
  const [bordas, setBordas] = useState([]);
  const [borda, setBorda] = useState(produto?.borda ? produto?.borda : "");
  const [bordaPrice, setBordaPrice] = useState(0);

  if (!produto || !config) {
    const _destination = localStorage.getItem("_slugSearch");
    history.push(`/${_destination}`);
  }

  const styles = {
    main: {
      backgroundColor: "#323232",
      height: "fit-content",
      padding: "5px",
      marginTop: "5rem",
    },
    comboSabor: {
      backgroundColor: validPizza ? "red" : "orange",
      color: validPizza ? "white" : "black",
      height: "30px",
      margin: "5px 4px 0 3px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    labelBorda: {
      height: "20px",
      backgroundColor: "orange",
      margin: "10px 0 0 13px",
      width: "30%",
      paddingLeft: "5px",
      borderTopRightRadius: "10px",
    },
    valorTotal: {
      border: "2px solid orange",
      textAlign: "center",
      color: "white",
      marginTop: "7px",
      marginBottom: "7px",
      fontWeight: "bold",
      borderRadius: "10px",
    },
    comboBorda: {
      marginLeft: "13px",
      marginRight: "13px",
      background: "#323232",
      border: `2px solid ${config?.theme.secondary}`,
      color: "black",
    },
  };

  useEffect(() => {
    if (produto) {
      setComplementos(produto.complemento);
    }
  }, [produto]);

  const updateTotalOpcional = useCallback(() => {
    let nTotalFinal = 0;
    let nTotal = 0;
    let nQuant = 0;
    let nTotalComplemento = 0;

    if (produto.maiorvalor) {
      complementos
        .filter((cp) => cp.tipo === "tiSabor")
        .forEach((localProduto) => {
          if (nTotalFinal < localProduto.preco && localProduto.quantidade > 0) {
            nTotalFinal = localProduto.preco;
          }
        });
    } else {
      complementos
        .filter((cp) => cp.tipo === "tiSabor")
        .forEach((localProduto) => {
          nTotal += localProduto.preco * localProduto.quantidade;
          nQuant += localProduto.quantidade;
        });

      if (nTotal > 0) {
        nTotalFinal = nTotal / nQuant;
      }
    }

    complementos
      .filter((cp) => cp.tipo !== "tiSabor")
      .forEach((localProduto) => {
        nTotalComplemento += localProduto.preco * localProduto.quantidade;
      });

    setTotalPizza(nTotalFinal + bordaPrice);
    setTotalOpcional(nTotalComplemento);
  }, [produto, bordaPrice, complementos]);

  useEffect(() => {
    if (validSup) {
      toast.current.show({
        severity: "Erro",
        summary: "Limite excedido!",
        detail: `Selecione o máximo de ${produto.quantidadesabor} sabores.`,
      });
    }
    //    eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSup]);

  useEffect(() => {
    if (produto) {
      setObservacao(produto.observacao);

      let noBordas = [];
      const param = produto.json !== "" ? JSON.parse(produto.json) : null;
      const bordaObrigatoria = param?.obrigaborda;

      if (!bordaObrigatoria) {
        noBordas = [{ name: "SEM BORDA", price: 0, desc: "SEM BORDA" }];
      }

      let newBordas = [];

      if (produto.bordas) {
        newBordas = JSON.parse(produto.bordas).map((item) => ({
          name: item.name,
          price: item.price,
          desc: `${item.name} (R$ ${Number(item.price).toFixed(2)})`,
        }));
      }

      const finalBordas = noBordas.concat(newBordas);

      if (newBordas.length === 0) {
        setBorda("SEM BORDA");
      }

      setBordas(finalBordas);

      let filterBorda = finalBordas.filter((b) => b.name === borda);

      if (filterBorda.length > 0) {
        setBordaPrice(Number(filterBorda[0]?.price));
      } else {
        setBordaPrice(0);
      }

      updateTotalOpcional();
    }
  }, [borda, produto, updateTotalOpcional]);

  useEffect(() => {
    if (produto) {
      if (Object.keys(produto).length > 0) {
        setTotal(quantidade * (totalPizza + totalOpcional));
      }
    }
  }, [quantidade, totalPizza, produto, bordaPrice, totalOpcional]);

  function onComplementoChange(newcomp) {
    updateTotalOpcional(newcomp);
    setComplementos(newcomp);
  }

  function onComposicaoChange(newcomp) {
    setComposicoes(newcomp);
  }

  function handleObsChange(e) {
    setObservacao(e.target.value);
  }

  function onValidade(value) {
    setValid(value);
  }

  function onValidadePizza(value, sup) {
    setValidPizza(value);
    setValidSup(sup);
  }

  function onConfirm() {
    let item = {
      produto: {
        ...produto,
        complemento: complementos,
        composicao: composicoes,
        observacao,
        borda,
      },
      quantidade: quantidade,
      itemcomposicao: composicoes.map(({ descricao, ativado }) => ({
        descricao,
        ativado,
      })),
      itemcomplemento: complementos
        .filter((item) => item.quantidade > 0)
        .map((origem) => ({
          descricao: origem.descricao,
          quantidade: origem.quantidade,
          preco: origem.preco,
          tipo: origem.tipo,
        })),
      observacao,
      borda: borda === "SEM BORDA" ? "" : borda,
      valortotal: quantidade * (totalPizza + totalOpcional),
    };

    if (type === "insert") {
      addItemCart(item);
    } else {
      updateItemCart(item, index);
    }

    history.goBack();
  }

  const onChangeBorda = (e) => {
    let filterBorda = bordas.filter((b) => b.name === e.value);

    setBordaPrice(Number(filterBorda[0].price));
    setBorda(e.value);
    updateTotalOpcional();
  };

  if (!produto) return <div></div>;
  if (Object.keys(produto).length === 0) return <div></div>;

  return (
    <>
      <Toast
        ref={toast}
        position="top-left"
        style={{ backgroundColor: "rgba(255,0,0,0.9", color: "white" }}
      />
      <ToolBar back title={produto.descricao} />
      <div style={styles.main}>
        <InfoProduct
          config={config}
          item={produto}
          preco={totalPizza}
          opcional={totalOpcional}
        />
        {isOpen && !block && (
          <div style={styles.comboSabor}>
            {`Selecione até ${produto.quantidadesabor} sabores.`}
          </div>
        )}

        <div className="p-grid p-dir-col" style={{ marginTop: "5px" }}>
          {produto.composicao.length > 0 && (
            <ComposicaoCard
              config={config}
              produto={produto}
              onComposicaoChange={onComposicaoChange}
              cardapio={cardapio || !isOpen}
            />
          )}
          {produto.complemento.length > 0 && (
            <ComplementoCard
              onValidate={onValidade}
              onValidatePizza={onValidadePizza}
              config={config}
              produto={produto}
              onComplementoChange={onComplementoChange}
              cardapio={cardapio || !isOpen || block}
            />
          )}
          <div>
            <div style={styles.labelBorda}>Borda</div>
            <div id="combo-borda" style={styles.comboBorda}>
              <Dropdown
                className="p-borda"
                style={{
                  width: "100%",
                  backgroundColor: "#323232",
                  border: "0",
                }}
                autoWidth={true}
                options={bordas}
                optionLabel="desc"
                optionValue="name"
                value={borda}
                placeholder="Selecione a borda"
                onChange={onChangeBorda}
              />
            </div>
          </div>
          {!cardapio && (
            <ObservacaoCard
              observacao={observacao}
              config={config}
              onChange={handleObsChange}
            />
          )}
        </div>
        {!cardapio && isOpen && !block && (
          <Footer style={{ padding: "5px" }}>
            <div className="p-grid">
              <div
                className="p-col-4 p-col-align-center"
                style={{ textAlign: "center" }}
              >
                <InputNumber
                  inputStyle={{ width: "35px", textAlign: "center" }}
                  id="horizontal"
                  value={quantidade}
                  onValueChange={(e) => {
                    setQuantidade(e.value);
                  }}
                  showButtons
                  buttonLayout="horizontal"
                  step={1}
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  incrementButtonClassName="backgroundColor:orange"
                  min={1}
                  mode="decimal"
                  readOnly
                />
              </div>
              <div className="p-col-4" style={styles.valorTotal}>
                R$ {total.toFixed(2)}
              </div>
              <div className="p-col-4">
                <Button
                  disabled={valid || validPizza || borda === ""}
                  onClick={onConfirm}
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  {type === "insert" ? "ADICIONAR" : "ATUALIZAR"}
                </Button>
              </div>
            </div>
          </Footer>
        )}
      </div>
    </>
  );
};
