import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "./theme.css";
import "primeflex/primeflex.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();
