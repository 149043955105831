import React, { useCallback, useContext, useEffect } from "react";
import { ToolBar } from "./ToolBar";
import { MainContext } from "../../Contexts/MainContext";
import { Header } from "./Header";
import "./Custom.css";
import { ProductCardList } from "./ProductCardList";
import { Loading } from "./Loading";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { carregaOutro } from "../../services/empresaAPIService";

export const Home = () => {
  const {
    config,
    opt,
    isLoading,
    isAtiva,
    setSlug,
    isOpen,
    setIdent,
    setMesa,
    setTipo,
    setLocalEntrega,
    setSlugWithSearch,
    setHost,
    currentIp,
    setCurrentIp,
    setCardapio,
    setMsgPause,
    setPausa,
    setPausaEntrega,
    msgPause,
    pause,
    pausaEntrega,
  } = useContext(MainContext);
  const { slug } = useParams();
  const search = useLocation().search;
  const mesa = new URLSearchParams(search).get("mesa");
  const ident = new URLSearchParams(search).get("ident");
  const tipo = new URLSearchParams(search).get("tipo");
  const entrega = new URLSearchParams(search).get("entrega");
  const host = new URLSearchParams(search).get("host");
  const { data } = useQuery(
    ["outro", config?.empresa?.tenentID],
    carregaOutro,
    {
      enabled: !!config,
      cacheTime: 30 * 1000,
    }
  );

  console.log("Entrando");

  useEffect(() => {
    if (data && !tipo) {
      if ((data.status = 200)) {
        setPausaEntrega(data.data?.pausaentrega);
        setMsgPause(data.data?.mensagempausa);
        setPausa(data.data?.pausa);
        setCardapio(data.data?.pausa);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const GetIp = useCallback(async () => {
    console.log("capturando");

    try {
      //https://ipv4.seeip.org/jsonip
      const resp = await fetch("https://api4.ipify.org?format=json");

      if (resp.ok) {
        const body = await resp.json();
        setCurrentIp(body.ip);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setCurrentIp]);

  useEffect(() => {
    document.body.style.backgroundColor = "#323232";
    document.body.style.overflowX = "hidden";
    document.body.style.backgroundImage = "url('../static/1026.png')";
    document.body.style.backgroundBlendMode = "color-burn";
    document.body.style.backgroundRepeat = "repeat";

    setSlug(slug);
    setSlugWithSearch(slug + search);
    setIdent(ident);
    setMesa(mesa);
    setTipo(tipo);
    setLocalEntrega(entrega);
    setHost(host);
    setCardapio(tipo === "CARDAPIO" || pause === true);

    GetIp();
  }, [
    pause,
    config,
    slug,
    setSlug,
    setIdent,
    ident,
    setMesa,
    mesa,
    setTipo,
    tipo,
    setLocalEntrega,
    entrega,
    setSlugWithSearch,
    search,
    setHost,
    setCardapio,
    host,
    GetIp,
  ]);

  const styles = {
    wifiLabel: {
      color: "black",
      fontSize: "30px",
      marginTop: "30px",
      border: "1px solid black",
      boxShadow: "5px 5px 23px -3px darkslateblue",
      padding: "16px 10px",
      borderRadius: "10px",
      position: "relative",
    },
    wifiSublabel: {
      top: "-11px",
      left: "33%",
      position: "absolute",
      fontSize: "15px",
      color: "white",
      backgroundColor: "black",
      borderRadius: "5px",
      padding: "0px 5px",
      width: "100px",
    },
    wifiMain: {
      height: "100vh",
      backgroundColor: "orange",
      width: "100%",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "20px",
      padding: "10px",
    },
    mainWarning: {
      position: "absolute",
      fontSize: "2.8rem",
      width: "500px",
      height: "auto",
      padding: "20px",
      color: "white",
      top: "10vh",
      left: "10vw",
      textAlign: "center",
      justifyContent: "center",
      display: "grid",
      borderRadius: "25px",
      backgroundColor: "rgba(0,0,0,0.5)",
      alignItems: "center",
    },
    labelAtendimento: {
      color: "white",
      backgroundColor: "red",
      flex: "1",
      padding: "10px",
      textAlign: "center",
      height: "100%",
      marginTop: "-5px",
    },
  };

  const Minimo = () => {
    return (
      <div
        style={{
          backgroundColor: "#54DBE6",
          height: "40px",
          width: "100vw",
          textAlign: "center",
          alignItems: "center",
          padding: "10px",
          fontWeight: "bold",
        }}
      >
        {`Valor mínimo para entrega: R$ ${opt.minimo.toFixed(2)}`}
      </div>
    );
  };

  const ApenasRetirada = () => {
    return (
      <div
        style={{
          backgroundColor: "#54DBE6",
          height: "40px",
          width: "100vw",
          textAlign: "center",
          alignItems: "center",
          padding: "10px",
          fontWeight: "bold",
        }}
      >
        Apenas retirada no local.
      </div>
    );
  };

  const Pausa = () => {
    return (
      <div
        style={{
          backgroundColor: "#54DBE6",
          height: "auto",
          width: "100vw",
          textAlign: "center",
          alignItems: "center",
          padding: "10px",
          fontWeight: "bold",
          animation: "pauseAnimation 5s infinite",
        }}
      >
        {msgPause === "" ? "Loja Pausada" : msgPause}
      </div>
    );
  };

  const WifiAccess = ({ current, capturaIp }) => {
    if (!mesa) {
      return <div></div>;
    }

    return (
      <div style={styles.wifiMain}>
        <div>Para realizar seus pedidos, conecte-se na nossa Wifi!</div>
        <div
          style={{
            borderRadius: "10px",
            height: "30%",
            margin: "20px",
          }}
        >
          <div style={styles.wifiLabel}>
            <div style={styles.wifiSublabel}>Wifi</div>
            {`${opt.wifi}`}
          </div>

          <div style={styles.wifiLabel}>
            <div style={styles.wifiSublabel}>Senha</div>
            {`${opt.pass}`}
          </div>
          <div
            style={{
              margin: "10px",
              color: "white",
              backgroundColor: "black",
              borderRadius: "10px",
              marginTop: "10px",
              padding: "5px",
            }}
          >
            {current}
          </div>
        </div>
      </div>
    );
  };

  return isLoading ? (
    <Loading ativa={isAtiva} slug={slug} />
  ) : (
    <>
      <div className="mainWarning">
        <div style={styles.mainWarning}>
          Aplicação exclusiva para SmartPhones. Por favor, abra em seu
          smarthphone.
        </div>
        <img
          width={"100%"}
          height={"100%"}
          src="https://genuinesistemas.com.br/wp-content/uploads/2020/08/fundo-1.jpg"
          alt="fundo"
        />
      </div>
      <div className="main">
        <ToolBar userShow cartShow orderShow />
        <Header config={config} loja={mesa} />
        {!isOpen && (
          <div style={styles.labelAtendimento}>
            {config.empresa.atendimento}
          </div>
        )}
        {opt && opt.minimo > 0 && <Minimo />}
        {pausaEntrega && !tipo && <ApenasRetirada />}
        {pause && <Pausa />}
        {opt &&
        opt.localSecurity &&
        currentIp !== opt.ip &&
        currentIp !== "191.220.151.469" &&
        mesa ? (
          <WifiAccess current={currentIp} capturaIp={GetIp} />
        ) : (
          <ProductCardList config={config} />
        )}
      </div>
    </>
  );
};
